import React, { forwardRef, useState } from 'react';
import { ProjectSettingButton } from '../ProjectSettingButton';
import { EditIcon } from 'src/assets/img/edit-icon';
import { ProjectSettingsNames } from 'src/constants/projects';
import { PauseIcon } from 'src/assets/img/pause-icon';
import { DeleteIcon } from 'src/assets/img/delete-icon';
import { ProjectEditNameContent } from './ProjectEditNameContent';
import { ProjectDemoModeContent } from './ProjectDemoModeContent';
import { ProjectDeleteContent } from './ProjectDeleteContent';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { useGetAllProjectsQuery } from 'src/services';
import { useParams } from 'react-router-dom';
import { ProjectActivateContent } from 'src/components/ProjectSettings/Popups/ProjectActivateContent';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';

const ProjectPopupButton = forwardRef(
  ({ disabled, onClick = () => {}, variant, isActive }, ref) => {
    const { data: userInfo } = useGetUsersMeQuery();

    const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={disabled}
            title={'Edit project name'}
            icon={<EditIcon />}
          />
        );
      }
      case ProjectSettingsNames.ACTIVATE: {
        return (
          <div className="relative flex items-center">
            {!isCosupportManager && (
              <WarningPopover
                triggerClassName={clsx(
                  isActive ? 'left-[115px]' : 'left-[102px]',
                  'absolute top-1 z-10',
                )}
                fill="black"
                text="Please contact your CoSupport Account Manager to activate the project."
                contentMaxWidth="max-w-56"
              />
            )}
            <ProjectSettingButton
              onClick={onClick}
              disabled={disabled || !isCosupportManager}
              title={isActive ? 'Deactivate project' : 'Activate project'}
              icon={<PauseIcon />}
            />
          </div>
        );
      }
      case ProjectSettingsNames.DELETE: {
        return (
          <ProjectSettingButton
            onClick={onClick}
            disabled={disabled}
            title={'Delete project'}
            icon={<DeleteIcon />}
          />
        );
      }
      case ProjectSettingsNames.DEMO_MODE: {
        return (
          isCosupportManager && (
            <ProjectSettingButton onClick={onClick} title={'Demo mode'} />
          )
        );
      }
    }
  },
);

export const ProjectSettingsPopup = ({
  onClickTrigger,
  disableTrigger,
  variant,
}) => {
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const { data: projects } = useGetAllProjectsQuery();

  const currentProject = projects?.find((project) => project.id === +id);

  const closeFunc = () => {
    setOpen(false);
  };

  const content = () => {
    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return <ProjectEditNameContent close={closeFunc} />;
      }
      case ProjectSettingsNames.ACTIVATE: {
        return (
          <ProjectActivateContent
            close={closeFunc}
            isActive={currentProject?.is_active}
          />
        );
      }
      case ProjectSettingsNames.DELETE: {
        return <ProjectDeleteContent close={closeFunc} />;
      }
      case ProjectSettingsNames.DEMO_MODE: {
        return <ProjectDemoModeContent close={closeFunc} />;
      }
    }
  };

  const contentTitle = () => {
    switch (variant) {
      case ProjectSettingsNames.RENAME: {
        return 'Edit project name';
      }
      case ProjectSettingsNames.ACTIVATE: {
        return currentProject?.is_active
          ? 'Deactivate project'
          : 'Activate project';
      }
      case ProjectSettingsNames.DELETE: {
        return 'Delete project';
      }
      case ProjectSettingsNames.DEMO_MODE: {
        return 'Enable demo mode';
      }
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild className={clsx('cursor-pointer')}>
        <ProjectPopupButton
          disabled={disableTrigger}
          onClick={onClickTrigger}
          variant={variant}
          isActive={currentProject?.is_active}
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-start">{contentTitle()}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 pt-2">{content()}</div>
      </DialogContent>
    </Dialog>
  );
};
