import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import { CurrentSubscriptionPlanPopupForm } from './CurrentSubscriptionPlanPopupForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import AddButtonIcon from 'src/assets/img/add-button-icon.svg';
import EditButtonIcon from 'src//assets/img/edit-button-icon.svg';
import { useGetAccountsQuery } from 'src/services/accountsService';

export const CurrentSubscriptionPlanPopup = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);

  const { data: account } = useGetAccountsQuery();

  const closePopup = () => {
    setOpen(false);
  };

  const isPlanExist = Boolean(Object.keys(account?.general_plan || {})?.length);

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer transparent',
          )}
        >
          {isPlanExist ? (
            <>
              <p>
                Change <span className="max-md:hidden">subscription plan</span>
              </p>
              <img src={EditButtonIcon} alt="edit icon" />
            </>
          ) : (
            <>
              <p>
                Add <span className="max-md:hidden">subscription plan</span>
              </p>
              <img src={AddButtonIcon} alt="add icon" />
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add subscription plan</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <CurrentSubscriptionPlanPopupForm
            isPlanExist={isPlanExist}
            closePopup={closePopup}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
