import { Controller, useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme, stringScheme } from 'src/utils/validation-schemes';

export const AnalyticsDate = ({ onDateChange, selectOptions }) => {
  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      date: selectOptions[0],
    },
    resolver: yupResolver(
      object({
        date: object({
          label: stringScheme({ required: true }),
          disabled: booleanScheme(),
        }),
      }),
    ),
  });

  return (
    <div className={'input-container md:min-w-52 text-[.88rem]'}>
      <Controller
        control={control}
        name="date"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            type={'select'}
            selectOptions={selectOptions}
            invalidMessage={error?.message}
            value={value}
            isValid={!error?.message}
            onChange={(e) => {
              onChange(e);
              e.label && onDateChange && onDateChange(e);
            }}
            onBlur={() => trigger('date')}
          />
        )}
      />
    </div>
  );
};
