import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { getPercentageHelper } from 'src/helpers/getPercentageHelper';

export const SubscriptionPieChart = ({ data }) => {
  const [dataArray, setData] = useState([]);

  useEffect(() => {
    const productSum =
      (Number(data?.coagent) || 0) +
      (Number(data?.cobi) || 0) +
      (Number(data?.cocustomer) || 0);

    const coAgentPercent = getPercentageHelper(data?.coagent, productSum);
    const coBiPercent = getPercentageHelper(data?.cobi, productSum);
    const coCustomerPercent = getPercentageHelper(data?.cocustomer, productSum);
    setData([
      {
        name: 'CoCustomer',
        value: data?.cocustomer || 0,
        percent: coCustomerPercent || 0,
        color: '#A498FF',
      },
      {
        name: 'CoAgent',
        value: data?.coagent || 0,
        percent: coAgentPercent || 0,
        color: '#75F3FB',
      },
      {
        name: 'CoBi',
        value: data?.cobi || 0,
        percent: coBiPercent || 0,
        color: '#DFF800',
      },
    ]);
  }, [data]);

  return (
    <>
      <div className="min-w-32">
        <ResponsiveContainer
          width="100%"
          height="100%"
          style={{
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <PieChart width={100} height={100}>
            <Pie
              data={dataArray}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={60}
              fill="#8884d8"
              dataKey="value"
              blendStroke={true}
            >
              {dataArray.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full flex flex-col justify-center gap-3.5">
        <p className="text-ebony font-bold text-sm text-nowrap font-jamjuree">
          Usage by product
        </p>
        <div className="flex flex-col gap-2.5">
          {dataArray.map((item) => {
            return (
              !!item.value && (
                <div key={item.name} className="flex items-center gap-2">
                  <div
                    className="w-2 h-2 rounded-full"
                    style={{ backgroundColor: item.color }}
                  />
                  <div className="flex items-center gap-1">
                    <p className="font-medium text-ebony text-sm font-jamjuree">
                      {item.name}:
                    </p>
                    <p className="font-bold text-ebony text-sm font-jamjuree">
                      {item.value} ({item.percent.toFixed()}%)
                    </p>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
};
