import React, { useEffect } from 'react';
import Input from '../../../../components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { useCreatePaymentIntentMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { DialogClose, DialogFooter } from 'src/components/ui/dialog';
import Button from 'src/components/Button/Button';
import { stringScheme } from 'src/utils/validation-schemes';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';

export const ChargeSetupFeePopupForm = ({ closePopup }) => {
  const notification = useNotification();
  const loader = useLoader();

  const [
    createPaymentIntent,
    {
      isLoading: createPaymentIntentIsLoading,
      isError: createPaymentIntentIsError,
      error: createPaymentIntentError,
      isSuccess: createPaymentIntentIsSuccess,
    },
  ] = useCreatePaymentIntentMutation();

  useEffect(() => {
    if (createPaymentIntentIsLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [createPaymentIntentIsLoading]);

  useEffect(() => {
    if (createPaymentIntentIsError) {
      notification.error(getErrorMessage(createPaymentIntentError));
    }
  }, [createPaymentIntentIsError]);

  useEffect(() => {
    if (createPaymentIntentIsSuccess) {
      notification.success('Subscription setup fee was successfully created!');
      closePopup();
    }
  }, [createPaymentIntentIsSuccess]);

  const {
    control,
    trigger,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      setupFee: '',
      description: '',
    },
    resolver: yupResolver(
      object({
        setupFee: stringScheme({ required: false }).label('Setup Fee'),
        description: stringScheme({ required: false }).label('Description'),
      }),
    ),
  });

  const setupFeeWatch = watch('setupFee');

  const submit = () => {
    const formValues = getValues();
    createPaymentIntent({
      amount: Number(formValues.setupFee),
      description: formValues.description,
    });
  };

  return (
    <>
      <form id="setup-fee-form">
        <div className={'input-container'}>
          <Controller
            control={control}
            name="setupFee"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                prefix="$"
                type={'text'}
                label={'Setup Fee'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('setupFee')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type={'text'}
                label={'Description'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('description')}
              />
            )}
          />
        </div>
      </form>
      <DialogFooter>
        <DialogClose asChild>
          <Button style={{ width: 'auto' }} className="transparent black">
            Cancel
          </Button>
        </DialogClose>
        <DeletePopup
          deleteHandler={submit}
          submitBtnClassName="green"
          triggerIcon={
            <Button
              disabled={!isDirty || !setupFeeWatch}
              style={{ width: 'auto' }}
              type="submit"
              className="green"
            >
              Charge
            </Button>
          }
          deleteBtnText="Yes, confirm"
          title={'Charge setup fee'}
          descriptionClassName="text-lg font-medium !opacity-70"
          description="Would you like to proceed with charging the one-time setup fee?"
        />
      </DialogFooter>
    </>
  );
};
