import { baseApi } from './base.api';
import { PATH_BILLING_API } from '../constants/api-routes';
import {
  BILLING_SUBSCRIPTION_METHOD,
  BILLING_SUBSCRIPTION_UPDATE_MOD,
} from 'src/constants/billing';

export const billingService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethods: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
      }),
      providesTags: ['PaymentMethods'],
    }),
    getPayments: build.query({
      query: ({ starting_after = '', start_date = '', end_date = '' }) => ({
        url: `${PATH_BILLING_API.PAYMENTS}`,
        params: {
          ...(starting_after !== '' && { starting_after }),
          ...(start_date !== '' && { start_date }),
          ...(end_date !== '' && { end_date }),
        },
      }),
      providesTags: ['Payments'],
    }),
    getAllPlans: build.query({
      query: () => ({
        url: `${PATH_BILLING_API.PLANS_ALL}`,
      }),
    }),
    addPaymentMethods: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
        method: 'POST',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods', id }];
      },
    }),
    deletePaymentMethods: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHODS}`,
        method: 'DELETE',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods', id }];
      },
    }),
    setDefaultPaymentMethod: build.mutation({
      query: ({ payment_method_id }) => ({
        url: `${PATH_BILLING_API.PAYMENT_METHOD_DEFAULT}`,
        method: 'POST',
        body: {
          method: 'stripe',
          payment_method_id,
        },
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        }
        return [{ type: 'PaymentMethods' }];
      },
    }),
    getInvoicePdf: build.query({
      query: ({ payment_id }) => ({
        url: `${PATH_BILLING_API.INVOICE_PDF}`,
        params: {
          payment_id,
        },
      }),
    }),
    activateSubscription: build.mutation({
      query: ({ subscription_items }) => ({
        url: `${PATH_BILLING_API.CREATE_SUBSCRIPTION}`,
        method: 'POST',
        body: {
          method: 'stripe',
          subscription_items: subscription_items,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['ProjectPlans', 'Accounts'];
      },
    }),
    createSubscription: build.mutation({
      query: ({ subscription_item }) => ({
        url: `${PATH_BILLING_API.CREATE_SUBSCRIPTION}`,
        method: 'POST',
        body: {
          method: BILLING_SUBSCRIPTION_METHOD.STRIPE,
          subscription_item: subscription_item,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['Accounts'];
      },
    }),
    changeSubscription: build.mutation({
      query: ({
        subscription_item,
        subscription_update_mode = BILLING_SUBSCRIPTION_UPDATE_MOD.NEXT_PERIOD,
      }) => ({
        url: `${PATH_BILLING_API.CHANGE_SUBSCRIPTION}`,
        method: 'PUT',
        body: {
          method: BILLING_SUBSCRIPTION_METHOD.STRIPE,
          subscription_update_mode: subscription_update_mode,
          subscription_item: subscription_item,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['Accounts'];
      },
    }),
    cancelSubscription: build.mutation({
      query: ({ immediately = false }) => ({
        url: `${PATH_BILLING_API.CANCEL_SUBSCRIPTION}`,
        method: 'POST',
        params: {
          immediately,
        },
      }),
      async onQueryStarted({ immediately }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (immediately) {
            setTimeout(() => {
              dispatch(billingService.util.invalidateTags(['Accounts']));
            }, 1000);
          }
        } catch (error) {
          console.error('Запит не вдався:', error);
        }
      },
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['Accounts'];
      },
    }),
    createPaymentIntent: build.mutation({
      query: ({ amount, description }) => ({
        url: `${PATH_BILLING_API.CREATE_PAYMENT_INTENT}`,
        method: 'POST',
        body: {
          amount,
          description,
        },
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return ['Accounts'];
      },
    }),
  }),
});

export const {
  useGetAllPlansQuery,
  useGetPaymentsQuery,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodsMutation,
  useSetDefaultPaymentMethodMutation,
  useActivateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useSubscriptionAddItemMutation,
  useGetInvoicePdfQuery,
  useDeletePaymentMethodsMutation,
  useCreateSubscriptionMutation,
  useChangeSubscriptionMutation,
  useCreatePaymentIntentMutation,
} = billingService;
