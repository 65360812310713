import { ROUTE_PATHS } from 'src/utils/route-paths';
import { ProductsType as ProjectsType } from 'src/constants/products';
import { useParams } from 'react-router-dom';
import {
  ANALYTICS_ACCESS_ROLES,
  INTEGRATION_ACCESS_ROLES,
  KNOWLEDGE_BASE_ACCESS_ROLES,
  OVERVIEW_ACCESS_ROLES,
} from 'src/constants/roles';

export const getMenuItems = (type) => {
  const { id } = useParams();

  const overview = {
    id: 0,
    title: 'Overview',
    href: ROUTE_PATHS.DASHBOARD_PROJECT.replace(':id', id),
    visibleFor: [ProjectsType.agent, ProjectsType.bi, ProjectsType.customer],
    roles: OVERVIEW_ACCESS_ROLES,
  };

  const knowledgeBase = {
    id: 2,
    title: 'Knowledge Base',
    href: ROUTE_PATHS.DASHBOARD_PROJECT_KNOWLEDGE_BASE.replace(':id', id),
    disabled: !Boolean(id),
    visibleFor: [ProjectsType.customer, ProjectsType.bi],
    roles: KNOWLEDGE_BASE_ACCESS_ROLES,
  };

  const integration = {
    id: 3,
    title: 'Integrations',
    href: ROUTE_PATHS.DASHBOARD_PROJECT_INTEGRATIONS.replace(':id', id),
    disabled: !Boolean(id),
    visibleFor: [ProjectsType.customer, ProjectsType.agent, ProjectsType.bi],
    roles: INTEGRATION_ACCESS_ROLES,
  };

  const analytics = {
    id: 1,
    title: 'Analytics',
    href: ROUTE_PATHS.DASHBOARD_PROJECT_ANALYTICS.replace(':id', id),
    disabled: !Boolean(id),
    visibleFor: [ProjectsType.agent, ProjectsType.customer],
    roles: ANALYTICS_ACCESS_ROLES,
  };

  const AGENT_MENU_BAR = [overview, integration];
  const CUSTOMER_MENU_BAR = [overview, knowledgeBase, analytics, integration];
  const BI_MENU_BAR = [overview, knowledgeBase, integration];
  switch (type) {
    case ProjectsType.agent: {
      return AGENT_MENU_BAR;
    }
    case ProjectsType.customer: {
      return CUSTOMER_MENU_BAR;
    }
    case ProjectsType.bi: {
      return BI_MENU_BAR;
    }
    default: {
      return [];
    }
  }
};
