import './ProjectPage.css';
import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { CurrentProjectScreen } from './ProjectSreens/CurrentProjectScreen';
import { SettingsScreen } from './ProjectSreens/SettingsScreen';
import { useGetAllProjectsQuery } from 'src/services';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from 'src/provider/ThemeProvider';
import KnowledgeBasePage from 'src/pages/KnowledgeBasePage/KnowledgeBasePage';
import { ProductsType } from 'src/constants/products';
import IntegrationsPage from 'src/pages/IntegrationsPage/IntegrationsPage';
import AnalyticsPage from 'src/pages/AnalyticsPage/AnalyticsPage';
import { useGetUsersMeQuery } from 'src/services/usersService';
import {
  ANALYTICS_ACCESS_ROLES,
  INTEGRATION_ACCESS_ROLES,
  KNOWLEDGE_BASE_ACCESS_ROLES,
  OVERVIEW_ACCESS_ROLES,
} from 'src/constants/roles';

const ProjectPage = () => {
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const splideRef = useRef(null);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCustomer = searchParams.get('type') === ProductsType.customer;
  const isBi = searchParams.get('type') === ProductsType.bi;
  const { data: projectsData } = useGetAllProjectsQuery();
  const { setTheme } = useTheme();
  const { data: userInfo } = useGetUsersMeQuery();
  const role = userInfo?.role;

  useEffect(() => {
    if (projectsData && splideRef.current?.splide.index !== 1) {
      splideRef.current?.splide.go(1);
    }
  }, [projectsData, id]);

  useEffect(() => {
    if (splideRef.current) {
      splideRef.current?.splide.on('move', function () {
        window.scrollTo(0, 0);
      });
    }
  }, [splideRef]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  return (
    <React.Fragment>
      {isLgDown ? (
        <Splide
          ref={splideRef}
          className="project-mobile-slider"
          options={{
            start: 1,
            arrows: false,
            resetProgress: true,
          }}
        >
          <SplideSlide>
            <SettingsScreen />
          </SplideSlide>
          {OVERVIEW_ACCESS_ROLES.includes(role) && (
            <SplideSlide>
              <CurrentProjectScreen />
            </SplideSlide>
          )}
          {(isCustomer || isBi) &&
            KNOWLEDGE_BASE_ACCESS_ROLES.includes(role) && (
              <SplideSlide>
                <KnowledgeBasePage />
              </SplideSlide>
            )}
          {isCustomer && ANALYTICS_ACCESS_ROLES.includes(role) && (
            <SplideSlide>
              <AnalyticsPage />
            </SplideSlide>
          )}
          {INTEGRATION_ACCESS_ROLES.includes(role) && (
            <SplideSlide>
              <IntegrationsPage />
            </SplideSlide>
          )}
        </Splide>
      ) : (
        <CurrentProjectScreen />
      )}
    </React.Fragment>
  );
};

export default ProjectPage;
