import React, { useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';
import { Switch } from 'src/components/ui/switch';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { getPercentageHelper } from 'src/helpers/getPercentageHelper';
import { useSubscriptionProgressBarGradient } from 'src/hooks/useSubscriptionProgressBar';
import { SubscriptionPieChart } from 'src/pages/SubscriptionPage/CurrentSubscriptionPlansSection/CurrentSubscriptionPlans/SubscriptionPieChart';

export const DescTopTable = ({
  generalPlan,
  planRequestLimit,
  generalPlanRequestLimit,
}) => {
  const progressRef = useRef(null);
  const extraProgressRef = useRef(null);

  useSubscriptionProgressBarGradient({ progressRef });

  const metaData = generalPlan?.meta_data?.usage_by_products;

  const coAgent = metaData?.coagent || 0;
  const coBi = metaData?.cobi || 0;
  const coCustomer = metaData?.cocustomer || 0;

  const generalPercent = getPercentageHelper(
    coAgent + coBi + coCustomer + generalPlan.credits_count,
    planRequestLimit,
  );

  const extraGeneralPercentage = generalPercent > 100 && generalPercent - 100;

  useEffect(() => {
    if (extraProgressRef.current && extraGeneralPercentage) {
      extraProgressRef.current.style.width = `calc(${extraGeneralPercentage <= 100 ? extraGeneralPercentage : 100}% - 6px)`;
    }
  }, [extraGeneralPercentage, extraProgressRef]);

  const usedRequests =
    Number(planRequestLimit) -
    Number(generalPlanRequestLimit) +
    Number(generalPlan?.credits_count);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-grey_text">Plan</TableHead>
          <TableHead className="flex items-center gap-1 text-grey_text">
            AI replies balance
            <WarningPopover
              side="top"
              disableContentIcon
              textColor="text-ebony"
              text="AI replies used / AI replies included in the plan"
              contentClassName="!bg-white"
              triggerClassName=""
              fill="#B8C1C6"
            />
          </TableHead>
          <TableHead className="text-grey_text">Price</TableHead>
          <TableHead className="text-grey_text">Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell className="table-fixed max-w-80">
            <div className="col text-ebony hyphens-auto">
              <p className="font-medium text-[22px]">
                {generalPlan?.plan?.name}
              </p>
            </div>
          </TableCell>
          <TableCell>
            <div className="col flex-5 text-ebony hyphens-auto">
              <p className="text-base font-bold">
                {usedRequests}/{planRequestLimit}
              </p>
            </div>
          </TableCell>
          <TableCell className="max-w-80">
            <div className="flex flex-col text-ebony hyphens-auto">
              <p className="text-base font-bold">
                ${' '}
                {generalPlan?.custom_plan_settings?.fixed_price ||
                  generalPlan?.plan?.price}
              </p>
              <p className="opacity-50 font-medium text-xs">
                extra fee ${' '}
                {generalPlan?.custom_plan_settings?.usage_price ||
                  generalPlan?.plan?.extra_request_price}
                /reply
              </p>
            </div>
          </TableCell>
          <TableCell>
            <div className="flex items-start justify-start flex-col text-ebony hyphens-auto">
              <Switch
                checked={generalPlan.status === 'active'}
                disabled
                aria-readonly
                id="active-plan"
                type="green"
              />
              <label
                className="cursor-pointer font-medium text-xs"
                htmlFor="active-plan"
              >
                Active till{' '}
                {transformDateHelper(
                  generalPlan.current_period_end,
                  DateFormat.MONTH_DAY_YEAR_WITHOUT_COMA,
                )}
              </label>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={4}
            padding="px-5 pb-5"
            className="font-medium table-fixed w-full !border-t-0 "
          >
            <div className="h-8 w-full rounded-full bg-[#EAEEF2] p-[3px] relative overflow-hidden">
              <div
                ref={progressRef}
                className="w-[calc(100%-6px)] h-[calc(100%-6px)] absolute z-0 bg-blue-300 rounded-full"
              />
              {extraGeneralPercentage && (
                <div
                  title={`+ ${generalPlan.credits_count} Extra Replies Used`}
                  ref={extraProgressRef}
                  className="w-0 h-[calc(100%-6px)] absolute right-[3px] z-10 bg-error flex items-center justify-center rounded-full px-2.5"
                >
                  <p className="font-bold text-xs text-white text-center text-nowrap truncate">
                    + {generalPlan.credits_count} Extra Replies Used
                  </p>
                </div>
              )}
              <div className="w-full h-full flex justify-between items-center px-3.5 z-50">
                <div className="flex items-center gap-1 z-50">
                  <p className="font-bold text-xs text-ebony">
                    {usedRequests} Replies Used{' '}
                  </p>
                  {!!Object.keys(
                    generalPlan?.meta_data?.usage_by_products || {},
                  )?.length && (
                    <WarningPopover
                      triggerIcon={
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="14" height="14" rx="7" fill="white" />
                          <path
                            d="M6.3 10.5H7.7V6.3H6.3V10.5ZM7 4.9C7.19833 4.9 7.3647 4.8328 7.4991 4.6984C7.6335 4.564 7.70047 4.39787 7.7 4.2C7.69953 4.00213 7.63233 3.836 7.4984 3.7016C7.36447 3.5672 7.19833 3.5 7 3.5C6.80167 3.5 6.63553 3.5672 6.5016 3.7016C6.36767 3.836 6.30047 4.00213 6.3 4.2C6.29953 4.39787 6.36673 4.56423 6.5016 4.6991C6.63647 4.83397 6.8026 4.90093 7 4.9ZM7 14C6.03167 14 5.12167 13.8161 4.27 13.4484C3.41833 13.0807 2.6775 12.582 2.0475 11.9525C1.4175 11.323 0.918867 10.5821 0.551601 9.73C0.184334 8.87786 0.000467553 7.96786 8.86075e-07 7C-0.00046578 6.03213 0.183401 5.12213 0.551601 4.27C0.919801 3.41787 1.41843 2.67703 2.0475 2.0475C2.67657 1.41797 3.4174 0.919333 4.27 0.5516C5.1226 0.183867 6.0326 0 7 0C7.9674 0 8.8774 0.183867 9.73 0.5516C10.5826 0.919333 11.3234 1.41797 11.9525 2.0475C12.5816 2.67703 13.0804 3.41787 13.4491 4.27C13.8178 5.12213 14.0014 6.03213 14 7C13.9986 7.96786 13.8147 8.87786 13.4484 9.73C13.0821 10.5821 12.5834 11.323 11.9525 11.9525C11.3216 12.582 10.5807 13.0809 9.73 13.4491C8.87926 13.8173 7.96926 14.0009 7 14Z"
                            fill="black"
                          />
                        </svg>
                      }
                      side="top"
                      align="start"
                      disableContentIcon
                      contentClassName="!bg-white max-w-96"
                      triggerClassName=""
                      fill="#000000"
                      fillInner="white"
                    >
                      <div className="w-full h-32 min-w-32 flex gap-5">
                        <SubscriptionPieChart
                          data={generalPlan?.meta_data?.usage_by_products || {}}
                        />
                      </div>
                    </WarningPopover>
                  )}
                </div>
                <div>
                  <p className="font-bold text-xs text-ebony opacity-50">
                    {generalPlanRequestLimit} Replies Left
                  </p>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
