import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { DeleteIcon } from 'src/assets/img/delete-icon';

export const DeletePopup = ({
  deleteBtnText = 'Yes, delete',
  disabled = false,
  deleteHandler = () => {},
  title = 'Delete?',
  description = <>Are you sure you want to delete?</>,
  descriptionHeader = '',
  submitBtnClassName,
  descriptionClassName,
  triggerIcon = (
    <div className="p-2 [&_svg]:hover:opacity-100 [&_svg>path]:hover:fill-error [&_svg]:opacity-50 [&_svg]:transition [&_svg>path]:transition duration-300">
      <DeleteIcon fill="#05050D" />
    </div>
  ),
}) => {
  const [open, setOpen] = useState(false);

  const deleteFunc = () => {
    deleteHandler();
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        {triggerIcon}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-start">{title}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 pt-2">
          {descriptionHeader && (
            <h3 className="font-semibold text-base text-ebony">
              {descriptionHeader}
            </h3>
          )}
          <p
            className={clsx(
              descriptionClassName,
              'font-medium text-base text-ebony opacity-50',
            )}
          >
            {description}
          </p>
        </div>
        <DialogFooter className="max-md:flex-row max-md:gap-2.5">
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            className={clsx(
              submitBtnClassName
                ? submitBtnClassName
                : 'transparent red min-w-28',
            )}
            onClick={deleteFunc}
          >
            {deleteBtnText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
