import './AnalyticsCoCustomer.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ProjectSelectFilterPopup } from 'src/components/ProjectSelectFilterPopup/ProjectSelectFilterPopup';
import { useTheme } from 'src/provider/ThemeProvider';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import { useLazyGetCoCustomerAggregatedQuery } from 'src/services/coCustomerAnalyticsService';
import { useGetProjectByIdQuery } from 'src/services';
import { DateItems } from 'src/pages/AnalyticsPage/AnalyticsCustomer/DateItems';
import { AnalyticsDate } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsDate/AnalyticsDate';
import { AnalyticsCard } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCard/AnalyticsCard';
import { ResolvedEscalatedChart } from 'src/pages/AnalyticsPage/AnalyticsCustomer/ResolvedEscalatedChart/ResolvedEscalatedChart';
import { getErrorMessage } from 'src/utils/get-error-message';
import { AnalyticsCustomerPopup } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCustomerPopup/AnalyticsCustomerPopup';

const AnalyticsCoCustomer = () => {
  const [dateRange, setDateRage] = useState(DateItems[0].label);
  const { id } = useParams();
  const [aggregatedRequest, setAggregatedRequest] = useState({
    project_ids: [id],
    start_time: DateItems[0].getStartTime(),
    end_time: DateItems[0].getEndTime(),
    changes_percentage: true,
  });
  const [resolutionRate, setResolutionRate] = useState({
    value: 0,
  });
  const [totalProcessedTickets, setTotalProcessedTickets] = useState({
    value: 0,
  });
  const [resolvedTickets, setResolvedTickets] = useState({
    value: 0,
  });
  const [escalatedTickets, setEscalatedTickets] = useState({
    value: 0,
  });
  const [inProgressTickets, setInProgressTickets] = useState({
    value: 0,
  });
  const [aiRepliesSent, setAiRepliesSent] = useState({
    value: 0,
  });
  const [aiRepliesPerProcessedTicket, setAiRepliesPerProcessedTicket] =
    useState({ value: 0 });
  const [firstResponseTimeAvg, setFirstResponseTimeAvg] = useState({
    value: 0,
  });
  const [responseTimeAvg, setResponseTimeAvg] = useState({
    value: 0,
  });
  const [resolutionTimeAvg, setResolutionTimeAvg] = useState({
    value: 0,
  });
  const notification = useNotification();
  const loader = useLoader();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { setTheme } = useTheme();
  const [
    fetchAggregatedQuery,
    { data: aggregatedData, isLoading, isFetching, isError, error, refetch },
  ] = useLazyGetCoCustomerAggregatedQuery();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });
  useEffect(() => {
    isFetching ? loader.show() : loader.hide();
  }, [isFetching]);

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    setTheme('purple');
  }, []);

  useEffect(() => {
    if (currentProject && aggregatedRequest) {
      fetchAggregatedQuery(aggregatedRequest);
    }
  }, [currentProject, aggregatedRequest]);

  useEffect(() => {
    const dateItem = DateItems.find((item) => item.label === dateRange);

    setAggregatedRequest({
      ...aggregatedRequest,
      start_time: dateItem.getStartTime(),
      end_time: dateItem.getEndTime(),
    });
  }, [dateRange]);

  useEffect(() => {
    if (aggregatedData) {
      setResolutionRate(aggregatedData.resolutionRate);
      setTotalProcessedTickets(aggregatedData.totalProcessedTickets);
      setResolvedTickets(aggregatedData.resolvedTickets);
      setEscalatedTickets(aggregatedData.escalatedTickets);
      setInProgressTickets(aggregatedData.inProgressTickets);
      setAiRepliesSent(aggregatedData.aiRepliesSent);
      setAiRepliesPerProcessedTicket(
        aggregatedData.aiRepliesPerProcessedTicket,
      );
      setFirstResponseTimeAvg(aggregatedData.firstResponseTimeAvg);
      setResponseTimeAvg(aggregatedData.responseTimeAvg);
      setResolutionTimeAvg(aggregatedData.resolutionTimeAvg);
    }
  }, [aggregatedData]);

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx(
          'cocustomer-analytics-page-container',
          !isLgDown && 'relative',
        )}
        showFilter
        mobileFilterModal={<ProjectSelectFilterPopup id={id} />}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="dashboard z-10">
            <div className="card-body md:flex-row flex-col md:gap-[10px] gap-[6px] size-full">
              <div className="md:flex-row flex flex-col row size-full md:gap-[10px] gap-[6px] md:justify-end">
                <div></div>
                <AnalyticsDate
                  onDateChange={(selectedOption) =>
                    setDateRage(selectedOption.label)
                  }
                  selectOptions={DateItems.map((item) => {
                    return { label: item.label, disabled: item.disabled };
                  })}
                />
              </div>
            </div>
            <div className="card-body flex md:flex-row flex-col md:gap-[10px] gap-[6px] size-full border-[rgba(184, 193, 198, 0.40)] border rounded-[5px] md:rounded-[7px] p-[10px] md:p-[20px]">
              <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                <AnalyticsCard
                  className="bg-[#E5E6FF]"
                  title={'Resolution rate'}
                  icon={
                    <AnalyticsCustomerPopup
                      title={'Resolution rate'}
                      dateRange={dateRange}
                      dataKey={'resolutionRate'}
                      chartType={'lineChart'}
                      className={'bg-[#E5E6FF]'}
                    />
                  }
                  metric={`${resolutionRate.value}%`}
                  // percentage={resolutionRate.percentage}
                  toolTipDescription={
                    <span>
                      Total Resolved Tickets /<br />
                      Total Processed Tickets
                    </span>
                  }
                />
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#E5E6FF]"
                    title={'Total processed tickets'}
                    icon={
                      <AnalyticsCustomerPopup
                        title={'Total processed tickets'}
                        dateRange={dateRange}
                        dataKey={'totalProcessedTickets'}
                        chartType={'barChart'}
                        className={'bg-[#E5E6FF]'}
                      />
                    }
                    metric={`${totalProcessedTickets.value}`}
                    // percentage={totalProcessedTickets.percentage}
                    toolTipDescription={
                      <span>Total Resolved + Total Escalated</span>
                    }
                  />
                </div>
                <div className="flex md:flex-row flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#E5E6FF]"
                    title={
                      <span className={'text-ebony/50 text-xs font-medium'}>
                        Resolved tickets
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'Resolved tickets'}
                        dateRange={dateRange}
                        dataKey={'resolvedTickets'}
                        chartType={'barChart'}
                        className={'bg-[#E5E6FF]'}
                      />
                    }
                    metric={
                      <span className={'text-base'}>
                        {resolvedTickets.value}
                      </span>
                    }
                    // percentage={resolvedTickets.percentage}
                  />
                  <AnalyticsCard
                    className="bg-[#E5E6FF]"
                    title={
                      <span className={'text-ebony/50 text-xs font-medium'}>
                        Escalated tickets
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'Escalated tickets'}
                        dateRange={dateRange}
                        dataKey={'escalatedTickets'}
                        chartType={'barChart'}
                        className={'bg-[#E5E6FF]'}
                      />
                    }
                    metric={
                      <span className={'text-base'}>
                        {escalatedTickets.value}
                      </span>
                    }
                    // percentage={escalatedTickets.percentage}
                  />
                </div>
              </div>
              {Boolean(resolvedTickets.value || escalatedTickets.value) && (
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px] bg-[#E5E6FF]">
                  <div className="flex flex-col flex-1 min-h-[340px] md:min-h-[auto]">
                    <ResolvedEscalatedChart
                      resolved={resolvedTickets.value}
                      escalated={escalatedTickets.value}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F3F5FE]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        In progress tickets
                      </span>
                    }
                    // icon={
                    //   <AnalyticsCustomerPopup
                    //     title={'In progress tickets'}
                    //     dateRange={dateRange}
                    //     dataKey={'inProgressTickets'}
                    //     chartType={'barChart'}
                    //     bg={'#F3F5FE'}
                    //     className={'bg-[#F3F5FE]'}
                    //   />
                    // }
                    metric={
                      <span className={'text-xl'}>
                        {inProgressTickets.value}
                      </span>
                    }
                    // percentage={inProgressTickets.percentage}
                  />
                </div>
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F3F5FE]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        AI replies sent
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'AI replies sent'}
                        dateRange={dateRange}
                        dataKey={'aiRepliesSent'}
                        chartType={'barChart'}
                        className={'bg-[#F3F5FE]'}
                      />
                    }
                    metric={
                      <span className={'text-xl'}>{aiRepliesSent.value}</span>
                    }
                    // percentage={aiRepliesSent.percentage}
                    toolTipDescription={
                      <span>
                        Total number of AI replies sent in processed and
                        in-progress tickets
                      </span>
                    }
                  />
                </div>
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F3F5FE]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        AI replies per processed ticket
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'AI replies per processed ticket'}
                        dateRange={dateRange}
                        dataKey={'aiRepliesPerProcessedTicket'}
                        chartType={'barChart'}
                        className={'bg-[#F3F5FE]'}
                      />
                    }
                    metric={
                      <span className={'text-xl'}>
                        {aiRepliesPerProcessedTicket.value}
                      </span>
                    }
                    // percentage={aiRepliesPerProcessedTicket.percentage}
                    toolTipDescription={
                      <span>
                        AI replies sent in processed ticket / Total processed
                        tickets
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            {Boolean(
              firstResponseTimeAvg.value ||
                responseTimeAvg.value ||
                resolutionTimeAvg.value,
            ) && (
              <div className="card-body flex md:flex-row flex-col md:gap-[10px] gap-[6px] size-full border-[rgba(184, 193, 198, 0.40)] border rounded-[5px] md:rounded-[7px] p-[10px] md:p-[20px]">
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F1F8FF]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        First response time average
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'First response time average'}
                        dateRange={dateRange}
                        dataKey={'firstResponseTimeAvg'}
                        chartType={'lineChart'}
                        className={'bg-[#F1F8FF]'}
                      />
                    }
                    metric={
                      <span className={'text-xl'}>
                        {firstResponseTimeAvg.value}
                      </span>
                    }
                    // percentage={firstResponseTimeAvg.percentage}
                  />
                </div>
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F1F8FF]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        Response time average
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'Response time average'}
                        dateRange={dateRange}
                        dataKey={'responseTimeAvg'}
                        chartType={'lineChart'}
                        className={'bg-[#F1F8FF]'}
                      />
                    }
                    metric={
                      <span className={'text-xl'}>{responseTimeAvg.value}</span>
                    }
                    // percentage={responseTimeAvg.percentage}
                  />
                </div>
                <div className="flex flex-col flex-1 md:gap-[10px] gap-[6px] rounded-[5px] md:rounded-[7px]">
                  <AnalyticsCard
                    className="bg-[#F1F8FF]"
                    title={
                      <span className={'text-ebony/50 text-sm font-medium'}>
                        Resolution time average
                      </span>
                    }
                    icon={
                      <AnalyticsCustomerPopup
                        title={'Resolution time average'}
                        dateRange={dateRange}
                        dataKey={'resolutionTimeAvg'}
                        chartType={'lineChart'}
                        className={'bg-[#F1F8FF]'}
                      />
                    }
                    metric={
                      <span className={'text-xl'}>
                        {resolutionTimeAvg.value}
                      </span>
                    }
                    // percentage={resolutionTimeAvg.percentage}
                  />
                </div>
              </div>
            )}
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AnalyticsCoCustomer;
