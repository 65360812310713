import './ResolvedEscalatedChart.css';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

const COLORS = { Resolved: '#877CDB', Escalated: '#EDAEBE' };

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  centered = false,
}) => {
  // if "0" label will be on center of pie, if "1" label will be out of the border
  const FROM_CENTER_TO_BORDER = 0.4;
  const radius =
    innerRadius + (outerRadius - innerRadius) * FROM_CENTER_TO_BORDER;
  const x = centered ? '50%' : cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontWeight="500"
      textAnchor={centered ? 'middle' : x > cx ? 'start' : 'end'}
      dominantBaseline={centered ? 'middle' : 'central'}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const ResolvedEscalatedChart = ({ resolved = 0, escalated = 0 }) => {
  const [data, setData] = useState([
    { name: 'Resolved', value: resolved },
    { name: 'Escalated', value: escalated },
  ]);

  useEffect(() => {
    const newData = [];

    if (resolved) {
      newData.push({ name: 'Resolved', value: resolved });
    }

    if (escalated) {
      newData.push({ name: 'Escalated', value: escalated });
    }

    setData(newData);
  }, [resolved, escalated]);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Legend
        content={
          <>
            <div className="text-ebony/50 text-sm mt-[20px]">
              Resolved vs Escalated
            </div>
          </>
        }
      />

      <PieChart width={500} height={500}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={(args) =>
            renderCustomizedLabel({
              ...args,
              centered: data.length === 1,
            })
          }
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          blendStroke={true}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
          ))}
        </Pie>
        <Legend wrapperStyle={{ bottom: '20px' }} />
      </PieChart>
    </ResponsiveContainer>
  );
};
