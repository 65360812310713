import moment from 'moment/moment';

const iso = 'YYYY-MM-DDTHH:mm:ss';

export const DateItems = [
  {
    label: 'Today',
    disabled: false,
    groupBy: 'hour',
    getStartTime: () => moment().startOf('day').format(iso),
    getEndTime: () => moment().endOf('day').format(iso),
  },
  {
    label: 'Yesterday',
    disabled: false,
    groupBy: 'hour',
    getStartTime: () => moment().subtract(1, 'days').startOf('day').format(iso),
    getEndTime: () => moment().subtract(1, 'days').endOf('day').format(iso),
  },
  {
    label: 'This week',
    disabled: false,
    groupBy: 'day',
    getStartTime: () => moment().weekday(1).startOf('day').format(iso),
    getEndTime: () => moment().endOf('isoWeek').format(iso),
  },
  {
    label: 'Last week',
    disabled: false,
    groupBy: 'day',
    getStartTime: () =>
      moment().subtract(1, 'weeks').startOf('isoWeek').format(iso),
    getEndTime: () =>
      moment().subtract(1, 'weeks').endOf('isoWeek').format(iso),
  },
  {
    label: 'This month',
    disabled: false,
    groupBy: 'day',
    getStartTime: () => moment().startOf('month').startOf('day').format(iso),
    getEndTime: () => moment().endOf('month').format(iso),
  },
  {
    label: 'Last month',
    disabled: false,
    groupBy: 'day',
    getStartTime: () =>
      moment().subtract(1, 'months').startOf('month').format(iso),
    getEndTime: () => moment().subtract(1, 'months').endOf('month').format(iso),
  },
  {
    label: 'This year',
    disabled: false,
    groupBy: 'day',
    getStartTime: () => moment().startOf('year').format(iso),
    getEndTime: () => moment().endOf('year').format(iso),
  },
  {
    label: 'Last year',
    disabled: false,
    groupBy: 'day',
    getStartTime: () =>
      moment().subtract(1, 'year').startOf('year').format(iso),
    getEndTime: () => moment().subtract(1, 'year').endOf('year').format(iso),
  },
  {
    label: 'Custom',
    disabled: true,
    groupBy: 'day',
  },
];
