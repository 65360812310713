import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Bar,
  BarChart,
} from 'recharts';
import { formatDuration } from 'src/services/coCustomerAnalyticsService';

export const AnalyticsCustomerBarChartData = ({
  title = '',
  dataKey = '',
  data = [],
  groupBy = 'day',
}) => {
  const isDayRange = groupBy === 'day';
  const isTimeFormat = [
    'firstResponseTimeAvg',
    'resolutionTimeAvg',
    'responseTimeAvg',
  ].includes(dataKey);
  let YAxisLabel = isTimeFormat ? 'Min' : 'Tickets';
  YAxisLabel = dataKey === 'resolutionRate' ? '%' : YAxisLabel;
  YAxisLabel = ['aiRepliesSent', 'aiRepliesPerProcessedTicket'].includes(
    dataKey,
  )
    ? 'Replies'
    : YAxisLabel;

  const tooltipFormatter = (value) => {
    if (isTimeFormat) {
      return value ? formatDuration(value, 'minutes') : 0;
    }

    return `${value} ${YAxisLabel}`;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={'500px'}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 50,
          right: 50,
          left: 10,
          bottom: 50,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1 1" />
        <XAxis dataKey="formattedGroup">
          <Label
            value={isDayRange ? 'Days' : 'Hours'}
            offset={-10}
            position="insideBottomRight"
            className={'fill-ebony text-sm font-semibold'}
          />
        </XAxis>
        <YAxis>
          <Label
            value={YAxisLabel}
            offset={10}
            position="top"
            className={'fill-ebony text-sm font-semibold'}
          />
        </YAxis>
        <Tooltip
          formatter={tooltipFormatter}
          labelStyle={{
            color: '#FFF',
            fontFamily: 'Bai Jamjuree',
            fontSize: '14px',
          }}
          contentStyle={{
            borderRadius: '5px',
            background: '#05050D',
          }}
          itemStyle={{
            fontFamily: 'Bai Jamjuree',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
          }}
        />
        {Boolean(data.length) && (
          <ReferenceLine
            x={
              data[parseInt((data.length / 2).toFixed(0)) - 1]['formattedGroup']
            }
            stroke="#CCCCCC"
            strokeDasharray="1 1"
          />
        )}
        <Bar name={title} dataKey={dataKey} fill="#A498FF" />
      </BarChart>
    </ResponsiveContainer>
  );
};
