import { useAppSelector } from 'src/store';
import { useSearchParams } from 'react-router-dom';
import AnalyticsAgent from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgent';
import { ProductsType } from 'src/constants/products';
import AnalyticsCoCustomer from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCoCustomer';

const AnalyticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchProjectType = searchParams.get('type');
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const projectTypeVar = projectType || searchProjectType;

  switch (projectTypeVar) {
    case ProductsType.agent: {
      return <AnalyticsAgent />;
    }
    case ProductsType.customer: {
      return <AnalyticsCoCustomer />;
    }
    default: {
      return null;
    }
  }
};

export default AnalyticsPage;
