import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import { ChargeSetupFeePopupForm } from './ChargeSetupFeePopupForm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';

export const ChargeSetupFeePopup = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer transparent max-md:mt-0 max-md:!border-none',
          )}
        >
          <p className="max-md:text-xs max-md:font-medium max-md:underline max-md:underline-offset-4">
            Charge setup fee
          </p>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Charge setup fee</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <ChargeSetupFeePopupForm closePopup={closePopup} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
