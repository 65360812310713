import React, { useEffect, useState } from 'react';
import Button from 'src/components/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import EditButtonIcon from 'src/assets/img/edit-button-icon.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { emailScheme } from 'src/utils/validation-schemes';
import Input from 'src/components/Input/Input';
import { useUpdateAccountsMutation } from 'src/services/accountsService';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';

export const EmailForInvoicePopup = ({
  disabled = false,
  defaultEmail = '',
}) => {
  const [open, setOpen] = useState(false);

  const notification = useNotification();
  const loader = useLoader();

  const [
    updateAccountInfo,
    {
      isLoading: isLoadingUpdateAccountInfo,
      isError: isUpdateAccountInfoError,
      error: updateAccountInfoError,
      isSuccess,
    },
  ] = useUpdateAccountsMutation();

  useEffect(() => {
    if (isLoadingUpdateAccountInfo) {
      loader.show();
    } else {
      setOpen(false);
      loader.hide();
    }
  }, [isLoadingUpdateAccountInfo]);

  useEffect(() => {
    if (isUpdateAccountInfoError) {
      notification.error(getErrorMessage(updateAccountInfoError));
    }
  }, [isUpdateAccountInfoError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success('Email updated successfully!');
    }
  }, [isSuccess]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      email: defaultEmail,
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('New email'),
      }),
    ),
  });

  const watchEmail = watch('email');

  const submit = (data) => {
    updateAccountInfo({ email: data.email });
  };

  useEffect(() => {
    if (isSuccess) {
      reset({ email: watchEmail });
    }
  }, [isSuccess]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        <Button className="transparent">
          <span>Edit</span>
          <img src={EditButtonIcon} alt="edit icon" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Email for invoices</DialogTitle>
        </DialogHeader>
        <form>
          <div className="content">
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  type={'text'}
                  label={'New email for invoices'}
                  placeholder={'example@example.com'}
                  invalidMessage={error?.message}
                  value={value}
                  isValid={!error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </form>
        <div className="footer flex justify-end mt-5">
          <div className="help-container"></div>
          <div className="action-buttons flex gap-2.5">
            <Button
              className="transparent black min-w-28"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={!isDirty || isLoadingUpdateAccountInfo}
              onClick={handleSubmit(submit)}
              type="submit"
              form="edit-user-form"
              className="blue min-w-28"
            >
              {isLoadingUpdateAccountInfo ? 'Loading...' : 'Done'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
