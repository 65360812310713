export const BILLING_SUBSCRIPTION_UPDATE_MOD = {
  NOW: 'immediately',
  NEXT_PERIOD: 'on_next_period',
};

export const BILLING_SUBSCRIPTION_METHOD = {
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
};

export const BILLING_PLANS_TYPE = {
  CUSTOM: 'custom',
};
