import React from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { EmailForInvoicePopup } from './EmailForInvoicePopup/EmailForInvoicePopup';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';

export const EmailForInvoiceCard = () => {
  const { data: accounts, isFetching: accountIsFetching } =
    useGetAccountsQuery();

  return (
    <CardContainer className={`half-width flex-col justify-between`}>
      <div className="card-header">
        <div className="title">
          <p className="text-ebony font-bold text-sm">Email for invoices</p>
        </div>
        <div className="edit-button-container">
          <EmailForInvoicePopup defaultEmail={accounts?.email} />
        </div>
      </div>
      <div className="card-body  h-full py-1">
        <div
          className={
            'input-container full-width border rounded-[7px] h-full px-4 py-3.5 flex justify-between items-center'
          }
        >
          <LoaderWrapper
            loader={
              <div className="flex justify-between w-full items-end">
                <Skeleton className="h-4 w-full" />
              </div>
            }
            isLoading={accountIsFetching}
          >
            <p className="md:text-base text-sm font-medium text-ebony">
              {accounts?.email}
            </p>
          </LoaderWrapper>
        </div>
      </div>
    </CardContainer>
  );
};
