import React, { useEffect, useMemo } from 'react';
import Input from '../../../../components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme, stringScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import {
  useCancelSubscriptionMutation,
  useChangeSubscriptionMutation,
  useCreateSubscriptionMutation,
  useGetAllPlansQuery,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { DialogClose, DialogFooter } from 'src/components/ui/dialog';
import Button from 'src/components/Button/Button';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import {
  BILLING_PLANS_TYPE,
  BILLING_SUBSCRIPTION_UPDATE_MOD,
} from 'src/constants/billing';
import { Switch } from 'src/components/ui/switch';

export const CurrentSubscriptionPlanPopupForm = ({
  isPlanExist,
  closePopup,
}) => {
  const notification = useNotification();
  const loader = useLoader();

  const {
    data: plansAll,
    error: plansAllError,
    isError: plansAllIsError,
    isFetching: plansAllIsFetching,
  } = useGetAllPlansQuery();

  const { data: userInfo } = useGetUsersMeQuery();

  const { data: account } = useGetAccountsQuery();

  const [
    createSubscription,
    {
      isLoading: createSubscriptionIsLoading,
      isError: createSubscriptionIsError,
      error: createSubscriptionError,
      isSuccess: createSubscriptionIsSuccess,
    },
  ] = useCreateSubscriptionMutation();

  const [
    changeSubscription,
    {
      isLoading: changeSubscriptionIsLoading,
      isError: changeSubscriptionIsError,
      error: changeSubscriptionError,
      isSuccess: changeSubscriptionIsSuccess,
    },
  ] = useChangeSubscriptionMutation();

  const [
    cancelSubscription,
    {
      isLoading: cancelSubscriptionIsLoading,
      isError: cancelSubscriptionIsError,
      error: cancelSubscriptionError,
      isSuccess: cancelSubscriptionIsSuccess,
    },
  ] = useCancelSubscriptionMutation();

  useEffect(() => {
    if (
      createSubscriptionIsLoading ||
      cancelSubscriptionIsLoading ||
      changeSubscriptionIsLoading
    ) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [
    createSubscriptionIsLoading,
    cancelSubscriptionIsLoading,
    changeSubscriptionIsLoading,
  ]);

  useEffect(() => {
    if (changeSubscriptionIsError) {
      notification.error(getErrorMessage(changeSubscriptionError));
    }
  }, [changeSubscriptionIsError]);

  useEffect(() => {
    if (createSubscriptionIsError) {
      notification.error(getErrorMessage(createSubscriptionError));
    }
  }, [createSubscriptionIsError]);

  useEffect(() => {
    if (plansAllIsError) {
      notification.error(getErrorMessage(plansAllError));
    }
  }, [plansAllIsError]);

  useEffect(() => {
    if (cancelSubscriptionIsError) {
      notification.error(getErrorMessage(cancelSubscriptionError));
    }
  }, [cancelSubscriptionIsError]);

  useEffect(() => {
    if (createSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully created!');
      closePopup();
    }
  }, [createSubscriptionIsSuccess]);

  useEffect(() => {
    if (cancelSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully canceled!');
      closePopup();
    }
  }, [cancelSubscriptionIsSuccess]);

  useEffect(() => {
    if (changeSubscriptionIsSuccess) {
      notification.success('Subscription plan was successfully updated!');
      closePopup();
    }
  }, [changeSubscriptionIsSuccess]);

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      plan: { id: '', plan_type: '' },
      monthlyFee: '',
      aiReplies: '',
      extraReplyCost: '',
      cancelImmediately: false,
    },
    resolver: yupResolver(
      object({
        cancelImmediately: booleanScheme({ required: false }),
        monthlyFee: stringScheme({ required: false }).label('Monthly Fee'),
        aiReplies: stringScheme({ required: false }).label('AI Replies'),
        extraReplyCost: stringScheme({ required: false }).label(
          'Extra Reply Cost',
        ),
        plan: object({
          plan_type: stringScheme({ required: false }),
          id: stringScheme({ required: false }),
        })
          .required()
          .label('Plan'),
      }),
    ),
  });

  const planWatch = watch('plan');
  const cancelImmediatelyWatch = watch('cancelImmediately');

  const usedPlan = useMemo(() => {
    return plansAll?.find(
      (plan) => plan.id === account?.general_plan?.plan?.id,
    );
  }, [plansAll, account]);

  const submit = (formValues) => {
    if (isPlanExist) {
      if (formValues.plan.plan_type === BILLING_PLANS_TYPE.CUSTOM) {
        changeSubscription({
          subscription_item: {
            plan_id: Number(formValues.plan.id),
            fixed_price: formValues.monthlyFee,
            usage_price: formValues.extraReplyCost,
            custom_request_left: formValues.aiReplies,
          },
        });
      } else {
        changeSubscription({
          subscription_item: {
            plan_id: Number(formValues.plan.id),
          },
        });
      }
    } else {
      if (formValues.plan.plan_type === BILLING_PLANS_TYPE.CUSTOM) {
        createSubscription({
          subscription_item: {
            plan_id: Number(formValues.plan.id),
            fixed_price: formValues.monthlyFee,
            usage_price: formValues.extraReplyCost,
            custom_request_left: formValues.aiReplies,
          },
        });
      } else {
        createSubscription({
          subscription_item: {
            plan_id: Number(formValues.plan.id),
          },
        });
      }
    }
  };

  const updateSubscriptionNowHandler = () => {
    const formValues = getValues();
    if (formValues.plan.plan_type === BILLING_PLANS_TYPE.CUSTOM) {
      changeSubscription({
        subscription_update_mode: BILLING_SUBSCRIPTION_UPDATE_MOD.NOW,
        subscription_item: {
          plan_id: Number(formValues.plan.id),
          fixed_price: formValues.monthlyFee,
          usage_price: formValues.extraReplyCost,
          custom_request_left: formValues.aiReplies,
        },
      });
    } else {
      changeSubscription({
        subscription_update_mode: BILLING_SUBSCRIPTION_UPDATE_MOD.NOW,
        subscription_item: {
          plan_id: Number(formValues.plan.id),
        },
      });
    }
  };

  const cancelPlanHandler = () => {
    cancelSubscription({ immediately: cancelImmediatelyWatch });
  };

  useEffect(() => {
    if (Boolean(plansAll?.length)) {
      if (usedPlan) {
        reset({
          plan: { id: usedPlan?.id, plan_type: usedPlan?.plan_type },
          monthlyFee:
            account?.general_plan?.custom_plan_settings?.fixed_price ||
            usedPlan.price ||
            '',
          aiReplies:
            account?.general_plan?.custom_plan_settings?.request_limit ||
            usedPlan.request_limit ||
            '',
          extraReplyCost:
            account?.general_plan?.custom_plan_settings?.usage_price ||
            usedPlan.extra_request_price ||
            '',
          cancelImmediately: false,
        });
      }
    }
  }, [usedPlan, account, plansAll]);

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return (
    <>
      <form id="add-subscription-plan-form" onSubmit={handleSubmit(submit)}>
        <div className={'input-container'}>
          <LoaderWrapper
            isLoading={plansAllIsFetching}
            loader={
              <div className="flex flex-col">
                <label className="pt-1">Plan</label>
                <Skeleton className="w-full h-[52px]" />
              </div>
            }
          >
            <Controller
              control={control}
              name="plan"
              render={({ field: { onChange } }) => (
                <>
                  <label>Plan</label>
                  <Select
                    defaultValue={JSON.stringify(usedPlan)}
                    onValueChange={(value) => {
                      const parsedValue = JSON.parse(value);
                      onChange({
                        id: parsedValue.id,
                        plan_type: parsedValue?.plan_type,
                      });
                      setValue('monthlyFee', parsedValue.price);
                      setValue('aiReplies', parsedValue.request_limit);
                      setValue(
                        'extraReplyCost',
                        parsedValue.extra_request_price,
                      );
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue
                        placeholder={
                          <div className="flex flex-col">
                            <div className="flex gap-2 items-center">
                              <p className="font-medium text-base text-start relative">
                                {plansAll[0]?.name}
                              </p>
                              {isCosupportManager && (
                                <span className="rounded-full capitalize bg-ebony bg-opacity-10 px-2 text-xs opacity-60">
                                  {plansAll[0]?.plan_type}
                                </span>
                              )}
                            </div>
                            <span className="text-sm font-semibold opacity-40 text-wrap">
                              ${plansAll[0]?.price} –{' '}
                              {plansAll[0]?.request_limit} AI replies included,
                              ${plansAll[0]?.extra_request_price} extra reply.
                            </span>
                          </div>
                        }
                        defaultValue={JSON.stringify(usedPlan)}
                        className="[&>p]:font-bold"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {plansAll.map((plan) => {
                        return (
                          <SelectItem
                            key={plan.id}
                            value={JSON.stringify(plan)}
                          >
                            <div className="flex flex-col">
                              <div className="flex gap-2 items-center">
                                <p className="font-medium text-base text-start relative">
                                  {plan.name}
                                </p>
                                {isCosupportManager && (
                                  <span className="rounded-full capitalize bg-ebony bg-opacity-10 px-2 text-xs opacity-60">
                                    {plan?.plan_type}
                                  </span>
                                )}
                              </div>
                              <span className="text-sm font-semibold opacity-40 text-wrap">
                                ${plan?.price} – {plan?.request_limit} AI
                                replies included, ${plan?.extra_request_price}{' '}
                                extra reply.
                              </span>
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </>
              )}
            />
          </LoaderWrapper>
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="monthlyFee"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                prefix="$"
                readonly={planWatch?.plan_type !== BILLING_PLANS_TYPE.CUSTOM}
                type={'text'}
                label={'Monthly Fee'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('monthlyFee')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="aiReplies"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                readonly={planWatch.plan_type !== BILLING_PLANS_TYPE.CUSTOM}
                type={'text'}
                label={'AI replies'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('aiReplies')}
              />
            )}
          />
        </div>
        <div className={'input-container'}>
          <Controller
            control={control}
            name="extraReplyCost"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                prefix="$"
                readonly={planWatch.plan_type !== BILLING_PLANS_TYPE.CUSTOM}
                type={'text'}
                label={'Extra reply cost'}
                invalidMessage={error?.message}
                value={value}
                isValid={!error?.message}
                onChange={onChange}
                onBlur={() => trigger('extraReplyCost')}
              />
            )}
          />
        </div>
      </form>
      <DialogFooter>
        {!isPlanExist && (
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
        )}
        {isPlanExist && (
          <DeletePopup
            deleteHandler={cancelPlanHandler}
            triggerIcon={
              <Button
                style={{ width: 'auto' }}
                className="transparent red min-w-28"
              >
                Cancel plan
              </Button>
            }
            deleteBtnText="Yes, cancel"
            title={'Cancel subscription plan?'}
            description={
              <span className="flex flex-col gap-4">
                <span>
                  Are you sure you want to cancel{' '}
                  <span className="font-bold">{usedPlan?.name}</span>?
                </span>
                <span className="flex items-center gap-4">
                  <span>Cancel immediately?</span>
                  <Switch
                    type="green"
                    checked={cancelImmediatelyWatch}
                    onCheckedChange={(value) => {
                      setValue('cancelImmediately', value);
                    }}
                  />
                </span>
              </span>
            }
          />
        )}
        {isPlanExist && (
          <Button
            style={{ width: 'auto' }}
            disabled={!isDirty}
            className="transparent black min-w-28"
            type="submit"
            form="add-subscription-plan-form"
          >
            Update next billing cycle
          </Button>
        )}
        <Button
          disabled={!isDirty}
          style={{ width: 'auto' }}
          type="submit"
          form={isPlanExist ? '' : 'add-subscription-plan-form'}
          className="green"
          onClick={isPlanExist ? updateSubscriptionNowHandler : () => {}}
        >
          {isPlanExist ? 'Update now' : 'Save'}
        </Button>
      </DialogFooter>
    </>
  );
};
