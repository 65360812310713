export const ProjectSettingsNames = {
  RENAME: 'rename',
  ACTIVATE: 'activate',
  DELETE: 'delete',
  DEMO_MODE: 'demo',
};

export const ProjectPlanStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
  PAUSED: 'paused',
  TRIAL: 'trial',
  INCOMPLETE: 'incomplete',
  DRAFT: 'draft',
  PENDING: 'pending',
};
