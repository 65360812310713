export const PASSWORD_RESET_DONE_URL = '/v1/users/password-reset-done';
export const REGISTRATION_URL = 'v1/auth/registration';
export const LOGIN_URL = 'v1/auth/token';
export const LOGIN_BY_GOOGLE_URL = 'v1/auth/google';
export const LOGOUT_URL = 'v1/auth/logout';
export const REFRESH_TOKEN_URL = 'v1/auth/token/refresh';
export const USERS_ME_URL = 'v1/users/me';
export const USERS_ALL_URL = 'v1/users/all';
export const USERS_CREATE_URL = 'v1/users/create';
export const USER_UPDATE_URL = 'v1/users/{user_id}/update';
export const PROJECTS_URL = 'v1/projects';
export const ALL_PROJECTS_URL = 'v1/projects/all';
export const ADD_INTEGRATION_URL = 'v1/projects/{project_id}/integrations';
export const ADD_TEAM_MEMBER_URL = 'v1/projects/{project_id}/team-members';
export const GET_TEAM_MEMBERS_URL = 'v1/projects/{project_id}/team-members';
export const DELETE_TEAM_MEMBERS_URL =
  'v1/projects/{project_id}/team-members/{team_member_id}';
export const INTEGRATION_URL =
  'v1/projects/{project_id}/integrations/{integration_id}';
export const INTEGRATION_FILES_URL =
  'v1/projects/{project_id}/integrations/{integration_id}/files';
export const INTEGRATION_FILES_ADD_URL =
  'v1/projects/{project_id}/integrations/{integration_id}/file/add';
export const INTEGRATION_DOCUMENT_ADD_URL =
  'v1/projects/{project_id}/integrations/{integration_id}/document/add';
export const INTEGRATION_FILES_DELETE_URL =
  'v1/projects/{project_id}/integrations/{integration_id}/file/delete';
export const INSTALL_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/cosupport-ai/kcpfdjeibdkkalafhdfjicjkcealkkhc';
export const INTEGRATION_SETTING_POPUP_ID = 'INTEGRATION_SETTING_POPUP_ID';
export const LATEST_VIEWED_PROJECT_ID = 'LATEST_VIEWED_PROJECT_ID';

export const EMAIL_REGEX = /^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
export const KNOWLEDGE_BASE_FILE_EXTENSIONS = ['.txt', '.pdf', '.md'];
