import React, { useEffect } from 'react';
import Button from '../../Button/Button';
import { useParams } from 'react-router-dom';
import {
  useGetProjectByIdQuery,
  useProjectDemoModeMutation,
} from 'src/services';
import { Switch } from 'src/components/ui/switch';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme } from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { ProductsTypeBack } from 'src/constants/products';

export const ProjectDemoModeContent = ({ close = () => {} }) => {
  const { id } = useParams();
  const notification = useNotification();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const [
    updateDemoMode,
    {
      isLoading,
      isError: isUpdateUpdateDemoMode,
      error: errorUpdateDemoMode,
      isSuccess,
    },
  ] = useProjectDemoModeMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
      notification.success('Demo mode was updated!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateUpdateDemoMode) {
      notification.error(getErrorMessage(errorUpdateDemoMode));
    }
  }, [isUpdateUpdateDemoMode]);

  const isDemoMode = currentProject?.is_demo_mode;

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      isDemo: isDemoMode,
    },
    resolver: yupResolver(
      object({
        isDemo: booleanScheme({ required: false }),
      }),
    ),
  });

  const watchIsDemo = watch('isDemo');

  const submit = (value) => {
    updateDemoMode({
      id: id,
      project_type: ProductsTypeBack[currentProject?.project_type],
      enable: value.isDemo,
    });
  };

  return (
    <>
      <div className="content">
        <form id="demo-form-form" onSubmit={handleSubmit(submit)}>
          <div className="sub-title flex flex-col gap-1.5">
            <Controller
              control={control}
              name="isDemo"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <span className="flex flex-col gap-4">
                  <span>Change demo mode.</span>
                  <span className="flex items-center gap-4">
                    <Switch
                      type="green"
                      checked={value}
                      onCheckedChange={onChange}
                    />
                    <span>{watchIsDemo ? 'On' : 'Off'}</span>
                  </span>
                </span>
              )}
            />
          </div>
        </form>
      </div>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          <Button
            className="transparent black min-w-28"
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            disabled={!isDirty || isLoading}
            type="submit"
            form="demo-form-form"
            className="blue min-w-28"
          >
            {isLoading ? 'Loading...' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  );
};
