import moment from 'moment/moment';

const constants = {
  accuracy: 'Accuracy',
  resolved_with_ai: 'Requests Resolved with AI',
  subdomain_resolved_with_ai: 'Requests Resolved with AI',
  bad_suggestions: 'Bad Suggestions Reported',
  subdomain_bad_suggestions: 'Bad Suggestions Reported',
  response_time: 'Average Response Time',
  first_response_time: 'Average First Response Time',
  subdomain_first_response_time: 'Average First Response Time',
  subdomain_response_time: 'Average First Response Time',
  initialRequest: {
    project_id: null,
    date_range: 'day',
    time_axis: 'minutes',
    // start_date: moment('05/01/2024').format('YYYY-MM-DD'),
    // end_date: moment('06/30/2024').format('YYYY-MM-DD'),
    start_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    end_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    metrics: [
      'first_response_time',
      'response_time',
      'accuracy',
      'subdomain_response_time',
      'resolved_with_ai',
      'bad_suggestions',
      'subdomain_bad_suggestions',
      'subdomain_first_response_time',
      'subdomain_resolved_with_ai',
    ],
    agents: [],
    data_visualization: 'linear',
  },
};

export default constants;
