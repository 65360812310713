import React, { useEffect } from 'react';
import { useTheme } from 'src/provider/ThemeProvider';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import { CurrentSubscriptionPlansSection } from './CurrentSubscriptionPlansSection/CurrentSubscriptionPlansSection';
import { PaymentHistorySection } from './PaymentHistorySection/PaymentHistorySection';
import { PaymentMethodCard } from './PaymentMethod/PaymentMethodCard';
import { PaymentDetailsCard } from './PaymentDetails/PaymentDetailsCard';
import { setIsCompanyChanged } from 'src/store/slices/companySlice';
import { useDispatch } from 'react-redux';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { SUBSCRIPTION_ACCESS_ROLES } from 'src/constants/roles';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { EmailForInvoiceCard } from 'src/pages/SubscriptionPage/EmailForInvoice/EmailForInvoiceCard';

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const { setTheme } = useTheme();
  const navigate = useNavigate();

  const { data: userInfo } = useGetUsersMeQuery();

  useEffect(() => {
    setTheme('green');
    dispatch(setIsCompanyChanged(false));
  }, []);

  useEffect(() => {
    if (!SUBSCRIPTION_ACCESS_ROLES.includes(userInfo?.role)) {
      navigate(PATH_DASHBOARD.ACCOUNT);
    }
  }, [userInfo]);

  return (
    SUBSCRIPTION_ACCESS_ROLES.includes(userInfo?.role) && (
      <React.Fragment>
        <PageContentWrapper className={'project-page-container'}>
          <CurrentSubscriptionPlansSection />
          <div className={'flex md:flex-row flex-col gap-0.625-rem'}>
            <PaymentDetailsCard />
            <PaymentMethodCard />
            <EmailForInvoiceCard />
          </div>
          <PaymentHistorySection />
        </PageContentWrapper>
      </React.Fragment>
    )
  );
};

export default SubscriptionPage;
