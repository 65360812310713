import React, { useEffect, useState } from 'react';
import Button from 'src/components/Button/Button';
import { PaymentMethodForm } from './PaymentMethodForm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import AddButtonIcon from 'src/assets/img/add-button-icon.svg';
import { getServerEnvironment } from 'src/env/env';
import { loadStripe } from '@stripe/stripe-js';
import EditButtonIcon from 'src/assets/img/edit-button-icon.svg';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import CloseIcon from 'src/assets/img/close-icon';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import {
  useDeletePaymentMethodsMutation,
  useSetDefaultPaymentMethodMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';

export const AddPaymentMethodPopup = ({ paymentMethods, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const [openTrigger, setOpenTrigger] = useState('');

  const [stripe, setStripe] = useState(null);

  const notification = useNotification();
  const loader = useLoader();

  const [
    setDefaultPaymentMethod,
    {
      isLoading: isLoadingSetDefaultPaymentMethod,
      isError: isSetDefaultPaymentMethodError,
      error: setDefaultPaymentMethodError,
      isSuccess: isSetDefaultPaymentMethodSuccess,
    },
  ] = useSetDefaultPaymentMethodMutation();

  const [
    deletePaymentMethod,
    {
      isError: isDeletePaymentMethodError,
      error: deletePaymentMethodError,
      isSuccess: deletePaymentMethodIsSuccess,
    },
  ] = useDeletePaymentMethodsMutation();

  useEffect(() => {
    if (isLoadingSetDefaultPaymentMethod) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingSetDefaultPaymentMethod]);

  useEffect(() => {
    if (isSetDefaultPaymentMethodError) {
      notification.error(getErrorMessage(setDefaultPaymentMethodError));
    }
    if (isDeletePaymentMethodError) {
      notification.error(getErrorMessage(deletePaymentMethodError));
    }
  }, [isSetDefaultPaymentMethodError, isDeletePaymentMethodError]);

  useEffect(() => {
    if (isSetDefaultPaymentMethodSuccess) {
      notification.success('Payment method was changed successfully.');
    }
    if (deletePaymentMethodIsSuccess) {
      notification.success('Payment method was deleted successfully.');
    }
  }, [isSetDefaultPaymentMethodSuccess, deletePaymentMethodIsSuccess]);

  useEffect(() => {
    if (!stripe && open) {
      (async () => {
        const { STRIPE_PUBLIC_KEY } = await getServerEnvironment();
        const stripeObject = await loadStripe(STRIPE_PUBLIC_KEY);

        setStripe(stripeObject);
      })();
    }
  }, [stripe, open]);

  const accordionHandler = (trigger) => {
    setOpenTrigger(trigger);
  };
  const accordionHandlerClose = () => {
    setOpenTrigger('');
  };

  const handlePaymentMethodClick = (id) => {
    setDefaultPaymentMethod({ payment_method_id: id });
  };

  const handlePaymentMethodDelete = (id) => {
    deletePaymentMethod({ payment_method_id: id });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
        )}
      >
        {paymentMethods?.data?.length ? (
          <Button className="transparent">
            <span>Edit</span>
            <img src={EditButtonIcon} alt="edit icon" />
          </Button>
        ) : (
          <Button className="transparent">
            <p>Add</p>
            <img src={AddButtonIcon} alt="add icon" />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="gap-5">
        <DialogHeader className="mb-2.5">
          {paymentMethods?.data?.length ? (
            <DialogTitle>Edit payment method</DialogTitle>
          ) : (
            <DialogTitle>Add payment method</DialogTitle>
          )}
        </DialogHeader>
        <Accordion
          type="single"
          collapsible
          className="w-full"
          onValueChange={accordionHandler}
          value={openTrigger}
        >
          <AccordionItem
            value="new-card"
            className="border-b-0 rounded-[7px] shadow bg-[#E9F7EC]"
          >
            <AccordionTrigger
              hideDefaultIcon
              className="py-5 px-5 pr-7.5 opacity-80 transition hover:opacity-100"
            >
              <div className="flex items-center justify-between w-full">
                <p className="font-semibold text-lg text-[#2C964C]">
                  Add new card
                </p>
                <CloseIcon className="rotate-45" stroke="#2C964C" />
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-5">
              <div className="grid gap-4 py-4">
                <PaymentMethodForm
                  stripe={stripe}
                  handleClose={accordionHandlerClose}
                />
              </div>
              <div className="flex gap-2.5 w-full justify-end">
                <Button
                  onClick={accordionHandlerClose}
                  style={{ width: 'auto' }}
                  className="transparent black"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!stripe}
                  style={{ width: 'auto' }}
                  type="submit"
                  form="add-payment-method-form"
                  className="green"
                >
                  Save
                </Button>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <div
          className={clsx(
            openTrigger && 'opacity-40 pointer-events-none',
            'flex transition flex-col gap-2.5',
          )}
        >
          {Boolean(paymentMethods?.data?.length) &&
            paymentMethods?.data.map((paymentItem) => {
              const isDefault = paymentItem.default;
              return (
                <div
                  onClick={() =>
                    !isDefault && handlePaymentMethodClick(paymentItem.id)
                  }
                  key={paymentItem.id}
                  className={clsx(
                    'transition w-full flex justify-between items-center rounded-[7px] shadow bg-white hover:bg-opacity-100 [&>div]:hover:opacity-100 pl-5 pr-7.5 py-5',
                    !isDefault && 'cursor-pointer bg-opacity-50',
                  )}
                >
                  <div
                    className={clsx(
                      'flex items-center gap-2.5 transition',
                      !isDefault && 'opacity-40',
                    )}
                  >
                    <div className="font-medium table-fixed w-4">
                      <div className="col">
                        <div className="w-3 h-3 rounded-full relative border border-ebony">
                          <div
                            className={clsx(
                              'absolute w-[7.5px] h-[7.5px] transition rounded-full bg-ebony top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                              isDefault ? 'block' : 'hidden',
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col role flex-5 text-base">
                      <p className="font-medium text-base text-ebony opacity-70">
                        <span className="capitalize">{paymentItem.brand}</span>{' '}
                        card
                        <span> ****{paymentItem.last4}</span>
                      </p>
                    </div>
                  </div>
                  <div onClick={(e) => e.stopPropagation()}>
                    <DeletePopup
                      title="Delete payment method?"
                      description={
                        <span className="font-medium text-base text-ebony opacity-70">
                          Are you sure you want to delete{' '}
                          <span className="capitalize">
                            {paymentItem.brand}
                          </span>{' '}
                          card
                          <span> ****{paymentItem.last4}</span>
                        </span>
                      }
                      deleteHandler={() =>
                        handlePaymentMethodDelete(paymentItem.id)
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </DialogContent>
    </Dialog>
  );
};
